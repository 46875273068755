.image-gallery-modal {
    max-width: 100%;
    padding: 10px;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.image-wrapper {
    position: relative;
    text-align: center;
    max-width: 100%;
}

.gallery-image {
    max-width: 100%;
    height: auto;
}

.image-counter {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
}

.image-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.image-nav.prev {
    left: 10px;
}

.image-nav.next {
    right: 10px;
}

@media (max-width: 576px) {
    .image-gallery-modal {
        padding: 5px;
    }

    .image-wrapper {
        width: 100%;
    }

    .image-nav {
        padding: 5px;
    }
}
