.banner-container {
  position: relative;
  text-align: center;
  color: white;
}

.banner-image {
  width: 100%;
  height: auto;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 15px;
  /* Add some padding to ensure text doesn't touch the edges on smaller screens */
}

.banner-text h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.banner-text p {
  font-size: 1.25rem;
  max-width: 600px;
  margin: 0 auto;
}


.flying-images-container {
  position: relative; /* Make container relative for positioning */
  width: 100%; /* Set container width to fill its parent */
  height: 100vh; /* Set container height to fill viewport height */
  overflow: hidden; /* Hide content that overflows the container */
}

.flying-image {
  position: absolute;
  /* Position images absolutely for movement */
  width: 100px;
  /* Adjust image size as desired */
  height: 100px;
  /* Adjust image size as desired */
  /* Add some margin between images to prevent overlapping */
  margin: 20px;
}