body {
    margin: 0;
    padding: 0;
  }
  
  .App {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .crop-container0 {
    position: relative;
    margin: 30px auto;
    width: 100%;
    height: 300px;
  }
  
  .crop-container {
    position: relative;
    margin: 30px auto;
    width: 500px;
    height: 300px;
  }
  
  .crop-container2 {
    position: relative;
    margin: 30px auto;
    width: 500px;
    height: 300px;
  }
  
  .crop-container3 {
    position: relative;
    margin: 30px auto;
    width: 300px;
    height: 500px;
  }
  
  .controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
  }
  
  .slider {
    padding: 22px 0px;
  }
  